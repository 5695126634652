<!--学习情况统计（运营端）-->
<template>
  <div class="courseUser">
    <div class="pageContol listWrap templateList">
      <div class="framePage">
        <div class="framePage-title">
          <span>
            <em>当前位置：</em>
            <a href="javascript:;">数据统计</a>
            <i>></i>
            <a href="javascript:;" @click="init" class="cur-a">学习情况统计</a>
          </span>
        </div>
        <div class="framePage-body">
          <div
            class="operationControl"
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            "
          >
            <div class="searchbox" style="margin-bottom: 15px">
                <div title="机构名称" class="searchboxItem ci-full">
                <span class="itemLabel"
                    >机构名称:</span
                >
                <el-input
                  v-model="selectData.compName"
                  type="text"
                  size="small"
                  placeholder="请输入培训机构名称"
                  clearable
                />
                </div>
              <div title="开班时间" class="searchboxItem ci-full">
                  <span class="itemLabel">开班时间:</span>
                  <el-date-picker
                    v-model="opentime"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  />
              </div>
              <div title="结束时间" class="searchboxItem ci-full">
                <span class="itemLabel">结束时间:</span>
                <el-date-picker
                  clearable
                  size="small"
                  v-model="endTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </div>
            <div class="searchbox">
              <div title="行政区划" class="searchboxItem ci-full">
                <span class="itemLabel">行政区划:</span>
                <el-cascader
                  v-model="selectData.areaId"
                  :options="areatreeList"
                  :props="propsarea"
                  :disabled="disabled"
                  clearable
                  filterable
                  size="small"
                ></el-cascader>
              </div>
              <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span
                class="itemLabel"
                style="
                  min-width: 5rem;
                  text-align: right;
                  font-size: 14px;
                  padding: 0 0.5rem;
                "
                >班级搜索:</span
              >
              <el-select
                v-model="selectData.projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superPorjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
               <div title="班级状态:" class="searchboxItem ci-full">
                <span class="itemLabel">班级状态:</span>
                <el-select
                    size="small"
                    v-model="selectData.projectState"
                    placeholder="请选择班级状态"
                    clearable
                >
                    <el-option
                    v-for="item in projectStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    ></el-option>
                </el-select>
            </div>
              <div title="培训类型" class="searchboxItem ci-full">
                <span class="itemLabel">培训类型:</span>
                <el-cascader
                  :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  v-model="selectData.trainTypeId"
                  size="small"
                  clearable
                  :options="trainTypeList"
                  @change="handleTrainType"
                ></el-cascader>
              </div>
              
              <div class="df">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </div>
            </div>
          </div>
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  fixed
                />
                <el-table-column
                  label="班级编码"
                  align="left"
                  prop="projectCode"
                  show-overflow-tooltip
                  width="240"
                  fixed
                />
                <el-table-column
                  label="班级名称"
                  align="left"
                  show-overflow-tooltip
                  prop="projectName"
                  min-width="240"
                />
                <el-table-column
                  label="机构名称"
                  align="left"
                  show-overflow-tooltip
                  prop="compName"
                  min-width="200"
                />
                <el-table-column
                  label="行政区划"
                  align="left"
                  show-overflow-tooltip
                  prop="areaName"
                  min-width="200"
                />
                <el-table-column
                  label="培训类型"
                  align="left"
                  show-overflow-tooltip
                  prop="trainTypeNamePath"
                  min-width="150"
                />
                <el-table-column
                  label="开班时间"
                  align="left"
                  show-overflow-tooltip
                  prop="startDate"
                  min-width="120"
                >
                  <template slot-scope="{ row }">
                      {{ row.startDate | momentDate}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="结束时间"
                  align="left"
                  show-overflow-tooltip
                  prop="endDate"
                  min-width="120"
                >
                  <template slot-scope="{ row }">
                      {{ row.endDate | momentDate}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="班级状态"
                  align="left"
                  show-overflow-tooltip
                  prop="projectState"
                  min-width="100"
                >
                <template slot-scope="{ row }">
                    {{ $setDictionary("PROJECTSTATE", row.projectState) }}
                </template>
                </el-table-column>
                <el-table-column
                  label="学员总数"
                  align="left"
                  show-overflow-tooltip
                  prop="projectPeople"
                  min-width="100"
                />
                <el-table-column
                  label="学习人数"
                  align="left"
                  show-overflow-tooltip
                  prop="projectStudyNum"
                  min-width="100"
                />
                <el-table-column
                  label="完成人数"
                  align="left"
                  show-overflow-tooltip
                  prop="proCompleteNum"
                  min-width="100"
                />
                <el-table-column
                  label="操作"
                  width="150px"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="{ row }"  class="flexcc">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="downloadStudySituation(row.projectId)"
                      >下载学习情况</el-button
                    >
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "applySettlementInstitution",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      selectData: {
        //搜索object
        areaId: "", //行政区划
        trainTypeId: "", //培训类型
        compName: "", //所属机构
        projectState: "", //班级状态
        projectId: "", //班级id
      },
      opentime:[],//开始时间
      endTime:[],//结束时间
      areatreeList: [], //行政区划list
      trainTypeList: [], //培训类型list
      projectStateList: [], //班级状态list
      seaProjectCode: "", //班级编码
      seaProjectName: "", //班级名称
      projectList: [{}], //班级list
      propsarea: {
        //行政区划prop
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    init() {
      this.getTableHeight(); //获取列表高度
      this.getareatree(); //获取行政区划
      this.getTraintype(); //获取培训类型
      this.getclassstatusList(); //班级状态list
      this.getData(); //获取列表
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    //班级状态list
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.projectStateList = list.filter(
        (e) => e.value != "10" && e.value != "20" && e.value != "40"
      );;
    },
    //获取列表
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.selectData,
      };
      if(this.opentime&&this.opentime.length==2){
        params.startDateBeginDate = this.opentime[0]
        params.startDateEndDate = this.opentime[1]
      }
      if(this.endTime&&this.endTime.length==2){
        params.endDateBeginDate = this.endTime[0]
        params.endDateEndDate = this.endTime[1]
      }
      this.doFetch({
        url: "/biz/report/project/study/situation/pageList",
        params,
        pageNum,
      });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5 + 3) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superPorjectSelect();
      }
    },
    downloadStudySituation(projectId){
      this.$post(
        "/biz/report/project/study/situation/export",
        {projectId},
      ).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(res.message);
        }
      });
    }
  },
  mounted: function () {},
};
</script>

<style lang="less" scope>
.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  > .cell {
    text-align: left;
  }
}
.el-table th.is-right {
  > .cell {
    text-align: right;
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
